import { useState  } from 'react';
import fourtySeven from '../reports/Market Report Sale 47.pdf';
import fourtySix from '../reports/Market Report Sale 46.pdf';
import fourtyFive from '../reports/Market Report Sale 45.pdf';
import fourtyFour from '../reports/Market Report Sale 44.pdf';
import fourtyThree from '../reports/Market Report Sale 43.pdf';
import fourtyTwo from '../reports/Market Report Sale 42.pdf';
import fourtyOne from '../reports/Market Report Sale 41.pdf';
import fourty from '../reports/Market Report Sale 40.pdf';
import thirtyNine from '../reports/Market Report Sale 39.pdf';
import thirtyEight from '../reports/Market Report Sale 38.pdf';
import thirtySeven from  '../reports/Market Report Sale 37.pdf';
import thirtySix from '../reports/Market Report Sale 36.pdf';
import fiftyOne from '../reports/Market Report Sale 51.pdf';
import fifty from '../reports/Market Report Sale 50.pdf';
import fourtyNine from '../reports/Market Report Sale 49.pdf';
import fourtyEight from '../reports/Market Report Sale 48.pdf';
import one from '../reports/Market Report Sale 01.pdf';
import two from '../reports/Market Report Sale 02.pdf';
import three from '../reports/Market Report Sale 03.pdf';
import four from '../reports/Market Report Sale 04.pdf';
import five from '../reports/Market Report Sale 05.pdf';
import six from '../reports/Market Report Sale 06.pdf';

import './Weekly.css';

function Weekly () {

const pdfFiles = [
  six,
five,
four,
three,
two,
one,
fourtyEight,
fourtyNine,
fifty,
fiftyOne,

    fourtySeven,
    fourtySix,
    fourtyFive,
    fourtyFour,
    fourtyThree,
    fourtyTwo,
    fourtyOne,
    fourty,
    thirtyNine,
    thirtyEight,
    thirtySeven,
    thirtySix,
    
    
];



  const [openMonth, setOpenMonth] = useState(null);

  const toggleMonth = (month) => {
    setOpenMonth(openMonth === month ? null : month);
  };

  const months= [
    {name: 'February 2024',
  report: ['Sale 06 - 7th February']},

    {name: 'January 2024',
  report: ['Sale 05 - 31st January', 'Sale 04 - 24th January', 'Sale 03 - 17th January', 'Sale 02 - 10th January', 'Sale 01 - 4th January' ]},
    {name: 'December 2023',
  report: ['Sale 51 - 20th December', 'Sale 50 - 14th December', 'Sale 49 - 6th December']},

    {name: 'November 2023',
  report: ['Sale 48 - 29th November', 'Sale 47 - 22nd November', 'Sale 46 - 15th November', 'Sale 45 - 8th November','Sale 44 - 1st November']},

    {name: 'October 2023',
  report: ['Sale 43 - 25th October','Sale 42 - 18th October','Sale 41 - 12th October',
'Sale 40 - 5th October'],
},
    {name: 'September 2023',
  report: ['Sale 39 - 27th September',
  'Sale 38 - 20th September', 'Sale 37 - 13th September',
  'Sale 36 - 6th September'
],
},
  
  ];

return (
    <div className="weekly-market-report">
       
        <ul>
            {months.map((month, index) => (
                <li key={index}><div className="report-holder">
                   <h5 onClick={() => toggleMonth (index)}>
                      <div className="case"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-briefcase-fill" viewBox="0 0 16 16">
  <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"/>
</svg> </div>{month.name} </h5></div>
                    {openMonth === index && (
                        <div className="month-report">
                            <ul>
                            {month.report.map((report, reportIndex) => (
                               <li key={reportIndex}>

                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
</svg>{report}
                                    <div className="button">
                        <a
                          className="view"
                          href={pdfFiles[reportIndex]}
                          target="_blank"
                          rel="noopener noreferrer"
                          title="preview document"
                          

                        >
                          view
                        </a>
                      <a  className="download"
                          href={pdfFiles[reportIndex]}
                          download
                          title="download file"
                          >download
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                          </svg>
                        </a>
                      </div> 
                                </li>
                            ))}
                            
                            </ul>

                        </div>
                    )}
                </li>
            ))}
        </ul>
    </div>
);
                            }

                            export default Weekly;
