import Catalog from "../documents/Catalog";
export default function SaleCatalogues () {

  
    return (
        <div className="results">
            <h3>ATLAS POST SALE CATALOGUES</h3>
            <Catalog/>
            
            <span>We Know Tea</span>

            

            
        </div>
    );
}