import Report from "../documents/Report";

export default function MarketReports () {
    return (
        <div className="markets">
            <h3>WEEKLY MARKET REPORTS</h3>
            
                <Report />

                                                
        
                                             </div>
        
    );
}