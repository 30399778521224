import './Footer.css'

export default function Footer () {
    return(
        <div className="foot-note">
            <p className="left">ALL RIGHTS RESERVED</p>
             <p className="center">We Know Tea</p>
             <p className="right">©2024 ~ ATLAS TEA BROKERS LTD </p>
            </div>
    );
}